import React from "react"
import Head from "../components/head.js"
import { graphql } from "gatsby"
import Navigation from "../components/navigation.js"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

export default function Post({ data }) {
  const post = data.markdownRemark
  let featuredImg = getImage(post.frontmatter.img)
  let imgAlt = post.frontmatter.imgalt
  let PDFread = post.frontmatter.pdfread
  let PDFprint = post.frontmatter.pdfprint
  const title = `${data.site.siteMetadata.title} | ${post.frontmatter.title}`
  
  return ( 
    <>     
      <Head title={title} />
      
      <Navigation />
      
      <div className="single">
        <section className="single-main">
          <aside className="single-aside">
            <GatsbyImage image={featuredImg} alt={imgAlt} />
            <ul>
              <li><a href={PDFread} target="_blank" rel="noopener noreferrer">[read]</a></li>
              <li><a href={PDFprint} target="_blank" rel="noopener noreferrer">[print]</a></li>
            </ul>
          </aside>
          <section className="single-content">
            <h1>{post.frontmatter.title}</h1>
            <div dangerouslySetInnerHTML={{ __html: post.html }} />
          </section>
        </section>
      </div>
    </>
  )
}


export const query = graphql`
  query($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      frontmatter {
        title
        img {
          childImageSharp {
            gatsbyImageData(
              placeholder: BLURRED
            )
          }
        }
        pdfread
        pdfprint
        imgalt
      }
    }
    site {
      siteMetadata {
        title
      }
    }
  }
  `
  
  
  